// Header.js

import React, { useState, useContext } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Container } from 'react-bootstrap';
import LogoImage1 from '../../assets/images/logo2.png';
import './Header.css';
import { LanguageContext } from '../../components/Language/LanguageContext';

const Header = ({ setShowModal }) => {
    const { language } = useContext(LanguageContext);
    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <Navbar bg="light" expand="lg" fixed="top" expanded={expanded}>
                <Container>
                    <div className="navbar-logo">
                        <a href="#Home"><img src={LogoImage1} alt="Logo" /></a>
                    </div>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#Place" onClick={() => setExpanded(false)}>
                                {language === 'esp' ? 'Lugar' : 'Lloc'}
                            </Nav.Link>
                            <Nav.Link href="#Agenda" onClick={() => setExpanded(false)}>
                                {language === 'esp' ? 'Agenda' : 'Agenda'}
                            </Nav.Link>
                            <Nav.Link href="#Asistencia" onClick={() => setExpanded(false)}>
                                {language === 'esp' ? 'Confirmación Asistencia' : 'Confirmació Assistència'}
                            </Nav.Link>
                            <Nav.Link href="#Photos" onClick={() => setExpanded(false)}>
                                {language === 'esp' ? 'Fotos' : 'Fotos'}
                            </Nav.Link>
                            <Nav.Link href="#Gift" onClick={() => setExpanded(false)}>
                                {language === 'esp' ? 'Detalle' : 'Detall'}
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <Nav.Link href="#" className="translate-icon" onClick={() => setShowModal(true)}>
                        <i className="bi bi-translate"></i>
                    </Nav.Link>
                </Container>
            </Navbar>
        </>
    );
};

export default Header;
