import React, { useContext } from 'react';
import './Photos.css';
import { Button } from 'react-bootstrap';
import GooglePhotoLogo from '../../assets/images/googlephotologo.png';
import { LanguageContext } from '../../components/Language/LanguageContext'; // Ajustar la ruta según sea necesario

const Photos = () => {
    const { language } = useContext(LanguageContext);

    return (
      <div id="Photos" className="section">
        <h1 className="section-title">
          {language === 'esp' ? 'Fotos' : 'Fotos'}
        </h1>
        <p className="section-description">
          {language === 'esp' ? 
            'Queremos que todas las fotos que hagáis en la boda las compartáis con nosotros, y así no perdamos ningún recuerdo. Os ponemos un enlace a Google Photos para que las compartáis con nosotros.' : 
            'Volem que totes les fotos que feu a la boda les compartiu amb nosaltres, i així no perdem cap record. Us posem un enllaç a Google Photos perquè les compartiu amb nosaltres.'}
        </p>
        <Button 
          className='google-photos-button button' 
          variant="primary" 
          href="https://photos.app.goo.gl/DERvUcbQYVKARqUu5" 
          target="_blank" // Esto hará que se abra en una nueva pestaña
        >
          {language === 'esp' ? 'Álbum de Google Photos' : 'Àlbum de Google Photos'}
          <img 
            src={GooglePhotoLogo} 
            alt="Google Photos" 
            style={{ maxWidth: '30px', marginLeft: '12px' }} // Ajusta tamaño y espaciado si es necesario
          />
        </Button>
        <p className="section-note">
          {language === 'esp' ? 
            'Iremos actualizando la página cuando tengamos las fotos del fotomatón y fotógrafo.' : 
            'Anirem actualitzant la pàgina quan tinguem les fotos del fotomaton i el fotògraf.'}
        </p>
      </div>
    );
};

export default Photos;
