import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './LanguageModal.css';


const LanguageModal = ({ show, onSelectLanguage }) => {
  return (
    <Modal
      show={show}
      centered
      backdrop="static" // Prevent closing by clicking on the backdrop
      keyboard={false} // Prevent closing with the escape key
      className="language-modal" // Custom class for additional styling
      dialogClassName="language-modal-dialog" // Add this line to control the modal dialog size
    >
      <Modal.Header>
        <Modal.Title>Idioma</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <Button 
          variant="outline-secondary" 
          size="lg" 
          onClick={() => onSelectLanguage('esp')}
          className="language-button"
        >
          Español
        </Button>
        <Button 
          variant="outline-secondary" 
          size="lg" 
          onClick={() => onSelectLanguage('cat')} 
          className="ml-4 language-button"
        >
          Català
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default LanguageModal;