import React from 'react';
import './Footer.css';
import LogoImage1 from '../../assets/images/logo2.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="navbar-logo">
                <img src={LogoImage1} alt="Logo" />
            </div>
        </footer>
    );
};

export default Footer;
