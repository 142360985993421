import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import MainImage from './components/MainImage/MainImage';
import Home from './components/Home/Home';
import Gift from './components/Gift/Gift';
import Photos from './components/Photos/Photos';
import { LanguageProvider } from './components/Language/LanguageContext';
import LanguageModal from './components/Language/LanguageModal';

import './App.css';

function App() {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'català');
  const [showModal, setShowModal] = useState(!localStorage.getItem('language'));

  const handleSelectLanguage = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    setShowModal(false);
    localStorage.setItem('language', selectedLanguage);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage && storedLanguage !== language) {
      setLanguage(storedLanguage);
    }
  }, [language]);

  return (
    <LanguageProvider language={language} setLanguage={setLanguage}>
      {showModal && <LanguageModal show={showModal} onSelectLanguage={handleSelectLanguage} />}
      <div className="app-container">
      <Header setShowModal={setShowModal} />
        <MainImage />
        <Home />
        <div className="colored-section">
          <div className="section-separator"></div>
          <Photos />
          <div className="section-separator"></div>
        </div>
        <Gift />
        <Footer />
      </div>
    </LanguageProvider>
  );
}

export default App;
