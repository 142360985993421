import React from 'react';
import HeaderImage from '../../assets/images/A30boda.jpg';
import './MainImage.css';


const MainImage = () => {

    return (
        <div>
            <div className="image-with-countdown">
                <img src={HeaderImage} alt="Albert and Alicia" className="header-main-image" />
            </div>
        </div>
    );
};

export default MainImage;
