import React, { useContext } from 'react';
import './Home.css';
import { LanguageContext } from '../../components/Language/LanguageContext'; // Adjust the path as needed

const Home = () => {
    const { language } = useContext(LanguageContext);

    return (
      <div id="Home" className="section">
        <h1 className="section-title-home">
          {language === 'esp' ? 
                'Gracias a todos por formar parte de este día inolvidable' : 
                'Gràcies a tots per formar part d\'aquest dia inoblidable'}
        </h1>
      </div>
    );
};
  
export default Home;
