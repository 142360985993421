import React, { useContext } from 'react';
import './Gift.css';
import { LanguageContext } from '../../components/Language/LanguageContext'; // Adjust the path as needed

const Gift = () => {
    const { language } = useContext(LanguageContext);

    return (
      <div id="Gift" className="section">
        <h1 className="section-title">
          {language === 'esp' ? 'Detalle' : 'Detall'}
        </h1>
        <p className="section-description">
          {language === 'esp' ? 
            'Vuestra presencia es el mayor regalo para nosotros, pero si nos queréis hacer un detalle, os dejamos el número de cuenta:' : 
            'La vostra presència és el major regal per a nosaltres, però si ens voleu fer un detall, us deixem el número de compte:'}
        </p>
        <h4 className="iban-section">
          IBAN: ES49 2085 9305 1803 3055 8439
        </h4>
        <p>
          {language === 'esp' ? 'Muchísimas gracias!' : 'Moltes gràcies!'}
        </p>
      </div>
    );
};

export default Gift;
